import axios from "axios";

let serverurl = "https://api.checklist.haut.id";
let port = "3000";
let localhost = "http://localhost:" + port;

let config = {
    baseURL: serverurl,
    // timeout: 10000
};

export default axios.create(config);

export { config };
